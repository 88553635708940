import React, { useEffect, useState } from "react";
import "../styles/AuctionViewing.css";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationModel from "../components/ConfirmationModel";

function AuctionViewing() {
  const location = useLocation();
  const Navigate = useNavigate();
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];
  const [auction, setAuction] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  useEffect(() => {
    Auction();
  }, []);

  const Auction = () => {
    axios({
      url: "https://lunarsenterprises.com:3007/cricket/view/auc",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        auc_id: id,
      },
    })
      .then((data) => {
        if (data.data.result) {
          console.log(data.data, "result");

          setAuction(data.data.list);
        } else {
          console.log("not working");
        }
      })
      .catch((err) => {
        console.log(err, "errorin AuctionView");
      });
  };
  const handleDelete = (response) => {
    if (response.isConfirm) {
      axios
        .post("https://lunarsenterprises.com:3007/cricket/delete/auc", {
          auc_id: selectedTeamId,
        })
        .then((response) => {
          if (response.data.result) {
            Navigate("/Auction");
          } else {
            alert(response.data.message);
          }
        })
        .catch((err) => console.error("Error deleting team:", err));
    }
    setShowConfirmModal(false);
  };
  const onDeleteConfirm = (teamId) => {
    setSelectedTeamId(teamId);
    setShowConfirmModal(true);
  };
  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  }
  const baseurl = "https://lunarsenterprises.com:3007/";
  return (
    <div className="auction-container">
      <Breadcrumbs />
      <div className="auction-header">
        <h1 className="auction-title">AUCTION DETAIL</h1>
        <a
          className="auction-link"
          onClick={() => Navigate(`/MainAuctionPage/${auction[0]?.au_id}`)}
        >
          Go to Auction Panel →
        </a>
      </div>

      <div className="auction-card">
        <div className="auction-info">
          <img
            src={baseurl + auction[0]?.au_image}
            alt="Auction Logo"
            className="auction-logo"
          />
          <div className="auction-details">
            <h2 className="auction-name">
              betting for {auction[0]?.au_name}{" "}
              <span className="alert-icon">❗</span>
            </h2>
            <p className="auction-code">A-Code: {auction[0]?.au_id}</p>
            <p className="auction-date">
              <span className="calendar-icon">📅</span>{" "}
              {formatDate(auction[0]?.au_date)}
              {auction[0]?.au_time}
            </p>
          </div>
        </div>

        <div className="auction-actions">
          <button
            className="action-btn"
            onClick={() => Navigate(`/AuctionTeam/${auction[0]?.au_id}`)}
          >
            Team
          </button>
          {/* <button
                className="action-btn"
                onClick={() => Navigate("/AuctionCategory")}
            >
                Category
            </button> */}
          <button
            className="action-btn"
            onClick={() => Navigate("/AuctionPlayer")}
          >
            Players
          </button>
          <button
            className="action-btn"
            onClick={() => Navigate(`/EditAuction/${auction[0]?.au_id}`)}
          >
            Edit
          </button>
          <button
            className="action-btn delete"
            onClick={() => onDeleteConfirm(auction[0]?.au_id)}
          >
            Delete
          </button>
        </div>
      </div>

      <div className="auction-summary">
        <div className="summary-item">
          <span className="summary-title">Point </span>
          <span className="summary-value">
            {auction[0]?.au_points_per_team}
          </span>
        </div>
        <div className="summary-item">
          <span className="summary-title">Basic Bid </span>
          <span className="summary-value">{auction[0]?.au_base_bid}</span>
        </div>
        <div className="summary-item">
          <span className="summary-title">Bid Incr </span>
          <span className="summary-value">
            {auction[0]?.au_bid_increase_by}
          </span>
        </div>
        <div className="summary-item">
          <span className="summary-title">
            {auction[0]?.au_max_players} PL/Team
          </span>
          <span className="summary-icon">👥</span>
        </div>
      </div>
      {showConfirmModal && (
        <ConfirmationModel
          id={selectedTeamId}
          callback={handleDelete}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
}

export default AuctionViewing;
